import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, fromJS } from 'immutable';

import {
    Box,
    Chip,
    Radio,
    Dialog,
    Button,
    FormLabel,
    Typography,
    IconButton,
    FormControl,
    RadioGroup,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import InputDatePicker from 'inputs/InputDatePicker';

import { getOrganOptions, HEART, LUNG } from 'data/organs';
import { getAuscultationAreaHeartOptions, getAuscultationAreaLungOptions } from 'data/auscultationArea';
import { getResultAreaHeartOptions, getResultAreaLungOptions } from 'data/resultArea';

import { SERVER_DATE_TIME_FORMAT } from 'utils/formatterUtils';

const styles = theme => ({
    dialog: {
        maxWidth: 900,
    },
    actions: {
        justifyContent: 'flex-end',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    radioGroup: {
        flexDirection: 'row',
    },
    label: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#4D5459',
    },
    chip: {
        marginRight: 8,
        marginTop: 8,
    },
    dateLabel: {
        marginRight: 8,
        paddingTop: 4,
        paddingBottom: 35,
    },
    date: {
        marginRight: 32,
        padding: 0,
        width: 190,
    },
});

const ChipElement = props => {
    const handleClick = useCallback(() => {
        props.onClick(props.item);
    }, [props.item, props.onClick]);

    return (
        <Chip
            {...props}
            onClick={handleClick}
        />
    );
};

ChipElement.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
};

const EMPTY_FILTERS = fromJS({
    organ: undefined,
    auscultationArea: undefined,
    assessmentFinding: undefined,
    fromDate: undefined,
    toDate: undefined,
});

const RecordsFiltersPopup = props => {
    const [filter, setFilter] = useState(props.query);

    const organOptions = useMemo(getOrganOptions, []);

    const auscultationAreaOptions = useMemo(() => {
        if (filter.getIn(['organ', 0]) === HEART) {
            return getAuscultationAreaHeartOptions();
        }
        if (filter.getIn(['organ', 0]) === LUNG) {
            return getAuscultationAreaLungOptions();
        }
        return [];
    }, [filter.getIn(['organ', 0])]);

    const resultOptions = useMemo(() => {
        if (filter.getIn(['organ', 0]) === HEART) {
            return getResultAreaHeartOptions();
        }
        if (filter.getIn(['organ', 0]) === LUNG) {
            return getResultAreaLungOptions();
        }
        return [];
    }, [filter.getIn(['organ', 0])]);

    const handleChangeOrgan = useCallback(e => {
        const { name, value } = e.target;
        setFilter(prevState => (
            prevState.set(name, List.of(value))
                .set('auscultationArea', undefined)
                .set('assessmentFinding', undefined)
        ));
    }, []);

    const handleChangeFromDate = useCallback(date => {
        setFilter(prevState => (
            prevState.set('fromDate', date ? date.startOf('day').format(SERVER_DATE_TIME_FORMAT) : undefined)
        ));
    }, []);

    const handleChangeToDate = useCallback(date => {
        setFilter(prevState => (
            prevState.set('toDate', date ? date.endOf('day').format(SERVER_DATE_TIME_FORMAT) : undefined)
        ));
    }, []);

    const handleClickAuscultationArea = useCallback(item => {
        setFilter(prevState => {
            if (prevState.get('auscultationArea') && prevState.get('auscultationArea').includes(item.value)) {
                const auscultationArea = prevState.get('auscultationArea').filterNot(el => el === item.value);
                return auscultationArea.isEmpty()
                    ? prevState.set('auscultationArea', undefined)
                    : prevState.set('auscultationArea', auscultationArea);
            }
            return prevState.updateIn(['auscultationArea'], (list = List()) => list.push(item.value));
        });
    }, []);

    const handleClickResult = useCallback(item => {
        setFilter(prevState => {
            if (prevState.get('assessmentFinding') && prevState.get('assessmentFinding').includes(item.value)) {
                const results = prevState.get('assessmentFinding').filterNot(el => el === item.value);
                return results.isEmpty()
                    ? prevState.set('assessmentFinding', undefined)
                    : prevState.set('assessmentFinding', results);
            }
            return prevState.updateIn(['assessmentFinding'], (list = List()) => list.push(item.value));
        });
    }, []);

    const handleClickApply = useCallback(() => (
        props.onClickApply(filter)
    ), [filter, props.onClickApply]);

    const handleClickReset = useCallback(() => (
        setFilter(EMPTY_FILTERS)
    ), []);

    return (
        <Dialog open classes={{ paperWidthSm: props.classes.dialog }} onClose={props.onClickClose}>
            <DialogTitle disableTypography>
                <Typography variant="h2" color="secondary">
                    {l('Filter')}
                </Typography>
                <IconButton className={props.classes.closeButton} onClick={props.onClickClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <FormControl component="fieldset">
                        <FormLabel className={props.classes.label} component="legend">
                            {l('Date')}
                        </FormLabel>
                        <Box display="inline-flex" mt={2}>
                            <Typography className={props.classes.dateLabel}>
                                {l('from')}
                            </Typography>
                            <InputDatePicker
                                disableFuture
                                name="fromDate"
                                placeholder={l('Please select date')}
                                value={filter.get('fromDate')}
                                className={props.classes.date}
                                onChange={handleChangeFromDate}
                            />
                            <Typography className={props.classes.dateLabel}>
                                {l('to')}
                            </Typography>
                            <InputDatePicker
                                disableFuture
                                name="toDate"
                                placeholder={l('Please select date')}
                                value={filter.get('toDate')}
                                className={props.classes.date}
                                onChange={handleChangeToDate}
                            />
                        </Box>
                    </FormControl>
                </Box>
                <Box mt={4}>
                    <FormControl component="fieldset">
                        <FormLabel className={props.classes.label} component="legend">
                            {l('Organ')}
                        </FormLabel>
                        <RadioGroup
                            name="organ"
                            value={filter.getIn(['organ', 0], '')}
                            className={props.classes.radioGroup}
                            onChange={handleChangeOrgan}
                        >
                            {
                                organOptions.map(item => (
                                    <FormControlLabel
                                        key={item.value}
                                        value={item.value}
                                        control={<Radio color="primary" />}
                                        label={item.name}
                                    />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box mt={4}>
                    <FormControl component="fieldset">
                        <FormLabel className={props.classes.label} component="legend">
                            {l('Auscultation area')}
                        </FormLabel>
                        <Box mt={1}>
                            {!auscultationAreaOptions.length ? (
                                <Typography color="secondary">
                                    {l('Choose organ to see variants')}
                                </Typography>
                            ) : (
                                auscultationAreaOptions.map(item => (
                                    <ChipElement
                                        key={item.value}
                                        clickable
                                        item={item}
                                        color={
                                            filter.get('auscultationArea')
                                            && filter.get('auscultationArea').includes(item.value)
                                                ? 'primary'
                                                : 'default'
                                        }
                                        className={props.classes.chip}
                                        label={item.name}
                                        onClick={handleClickAuscultationArea}
                                    />
                                ))
                            )}
                        </Box>
                    </FormControl>
                </Box>
                <Box mt={4}>
                    <FormControl component="fieldset">
                        <FormLabel className={props.classes.label} component="legend">
                            {l('Result')}
                        </FormLabel>
                        <Box mt={1}>
                            {!resultOptions.length ? (
                                <Typography color="secondary">
                                    {l('Choose organ to see variants')}
                                </Typography>
                            ) : (
                                resultOptions.map(item => (
                                    <ChipElement
                                        key={item.value}
                                        clickable
                                        item={item}
                                        color={
                                            filter.get('assessmentFinding')
                                            && filter.get('assessmentFinding').includes(item.value)
                                                ? 'primary'
                                                : 'default'
                                        }
                                        className={props.classes.chip}
                                        label={item.name}
                                        onClick={handleClickResult}
                                    />
                                ))
                            )}
                        </Box>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions className={props.classes.actions}>
                <Button color="secondary" variant="text" onClick={handleClickReset}>
                    {l('Reset filters')}
                </Button>
                <Button color="primary" variant="contained" onClick={handleClickApply}>
                    {l('Apply')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RecordsFiltersPopup.propTypes = {
    query: ImmutablePropTypes.map.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickApply: PropTypes.func.isRequired,
    onClickClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(RecordsFiltersPopup);
