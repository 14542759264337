import React from 'react';

import PropTypes from 'prop-types';

import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
    logout: {
        color: '#F45959',
    },
});

const HeaderMenu = props => (
    <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PaperProps={{
            style: {
                marginTop: 5,
                width: props.menuWidth + 20,
            },
        }}
        open={Boolean(props.anchorEl)}
        onClose={props.onClickClose}
        style={{ zIndex: '99999' }}
    >
        <MenuItem onClick={props.onClickProfile}>
            {l('My Profile')}
        </MenuItem>
        <MenuItem className={props.classes.logout} onClick={props.onClickLogout}>
            {l('Log out')}
        </MenuItem>
    </Menu>
);

HeaderMenu.propTypes = {
    menuWidth: PropTypes.number.isRequired,
    anchorEl: PropTypes.objectOf(PropTypes.object).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickClose: PropTypes.func.isRequired,
    onClickProfile: PropTypes.func.isRequired,
    onClickLogout: PropTypes.func.isRequired,
};

export default withStyles(styles)(HeaderMenu);
