export const MAIN_PATH = '/';

export const LOGIN = '/login';
export const RESET_PASSWORD = '/reset-password';
export const SIGN_UP = '/sign-up';
export const CONFIRM = '/confirm';
export const WELCOME = '/welcome';
export const RECORDS = '/records';
export const PROFILE = '/profile';
export const RECORD_NOTES = '/records/:recordId/notes';
export const PATIENTS = '/patients';
export const ADD_PATIENT = '/patients/add';
export const DEVICES = '/devices';
export const CHANGE_PASSWORD = '/profile/password';
export const HISTORY_RECORDS_BY_PATIENT = '/patients/:patientId/records';
export const PATIENT_BY_ID = '/patients/:patientId';
