import React, { memo, useCallback, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { goBack, push } from 'connected-react-router/immutable';

import { deleteDevice, getDevicesData, updateDevice } from 'actions/devices';
import { getLocation, getQuery } from 'selectors/router';
import { getDevices, getIsSubmitting, getPage, hasNext } from 'selectors/devices';

import { getPromptLocation, isOpen } from 'selectors/prompt';

import { resetPromptPopup, showPromptPopup } from 'actions/prompt';
import { changePristineForm } from 'actions/finalForms';

import Devices from 'components/devices/Devices';
import ConfirmationDialog from 'components/popups/ConfirmationDialog';

import { STAY } from 'data/prompt';

const mapStateToProps = state => ({
    devices: getDevices(state),
    query: getQuery(state),
    hasNext: hasNext(state),
    page: getPage(state),
    isSubmitting: getIsSubmitting(state),
    isOpenPromptConfirmPopup: isOpen(state),
    promptLocation: getPromptLocation(state),
    location: getLocation(state),
});

const mapDispatchToProps = {
    push,
    goBack,
    getDevicesData,
    deleteDevice,
    updateDevice,
    showPromptPopup,
    resetPromptPopup,
    changePristineForm,
};

const DevicesContainer = props => {
    const [isOpenDeletingConfirmation, setIsOpenDeletingConfirmation] = useState(false);
    let deviceId;

    useEffect(() => {
        props.getDevicesData({ params: props.query.toJS() });
    }, [props.query, props.getDevicesData]);

    const handleReadNext = useCallback(() => {
        if (!props.isSubmitting) {
            props.getDevicesData({ page: props.page + 1 });
        }
    }, [props.isSubmitting, props.page, props.getDevicesData]);

    const handleClickDelete = useCallback(id => {
        deviceId = id;
        setIsOpenDeletingConfirmation(true);
    }, []);

    const handleClickCancel = useCallback(() => {
        setIsOpenDeletingConfirmation(false);
    }, []);

    const handleClickConfirm = useCallback(() => {
        props.deleteDevice(deviceId);
        setIsOpenDeletingConfirmation(false);
    }, [props.deleteDevice]);

    const handleClickStay = useCallback(() => {
        props.resetPromptPopup(STAY);
    }, [props.resetPromptPopup]);

    const handleClickLeave = useCallback(() => {
        props.resetPromptPopup();
        if (props.promptLocation.get('key') !== props.location.get('key')) {
            props.push(props.promptLocation.toJS());
        }
    }, [props.promptLocation, props.location, props.resetPromptPopup, props.push]);

    return (
        <Fragment>
            {
                isOpenDeletingConfirmation && (
                    <ConfirmationDialog
                        isOpened
                        onClickOk={handleClickConfirm}
                        onClickCancel={handleClickCancel}
                    >
                        {l('Are you sure you want to delete this device from the system.')}
                    </ConfirmationDialog>
                )
            }
            <Devices
                hasMore={props.hasNext}
                devices={props.devices}
                onDeviceSubmit={props.updateDevice}
                isOpenPromptConfirmPopup={props.isOpenPromptConfirmPopup}
                loadMore={handleReadNext}
                onClickDelete={handleClickDelete}
                promptLocation={props.promptLocation}
                showPromptPopup={props.showPromptPopup}
                onClickStay={handleClickStay}
                onClickLeave={handleClickLeave}
                onChangePristineForm={props.changePristineForm}
            />
        </Fragment>
    );
};

DevicesContainer.propTypes = {
    devices: ImmutablePropTypes.list,
    promptLocation: ImmutablePropTypes.map,
    location: ImmutablePropTypes.map,
    query: ImmutablePropTypes.map.isRequired,
    hasNext: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isOpenPromptConfirmPopup: PropTypes.bool,
    page: PropTypes.number.isRequired,
    push: PropTypes.func.isRequired,
    getDevicesData: PropTypes.func.isRequired,
    deleteDevice: PropTypes.func.isRequired,
    updateDevice: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    resetPromptPopup: PropTypes.func.isRequired,
    changePristineForm: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(DevicesContainer));
