import { put, select, take, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { fromJS } from 'immutable';

import {
    SYNC_ALL,
    CREATE_RECORD,
    UPDATE_RECORD,
    DELETE_RECORD,
    CREATE_LOCAL_RECORD,
    DELETE_LOCAL_RECORD,
    UPDATE_LOCAL_RECORD,
    createRecord,
    updateRecord,
} from 'actions/records';
import { showMessage } from 'actions/messages';
import { DELETE_PATIENT } from 'actions/patients';
import { failAction, startAction, successAction } from 'actions/actionTypes';

import { getUserId } from 'selectors/session';

import { getRandomInt } from 'utils/generator';
import { extractData, saveDataUnsafe } from 'utils/localStorageUtils';

function* readSessionRecords() {
    const userId = yield select(getUserId);
    const records = JSON.parse(extractData('recordsStethoscope')) || {};
    return records[userId] || [];
}

function* saveSessionRecords(sessionRecords) {
    const userId = yield select(getUserId);
    const records = JSON.parse(extractData('recordsStethoscope')) || {};
    saveDataUnsafe('recordsStethoscope', { ...records, [userId]: sessionRecords });
}

function* handleAddRecord(action) {
    const userId = yield select(getUserId);
    const records = JSON.parse(extractData('recordsStethoscope')) || [];
    const { fileName, file, ...other } = action.payload;
    const sessionRecords = (records[userId] || []);
    const record = {
        ...other,
        id: getRandomInt(),
        createdAt: moment().toISOString(),
        notes: [],
        file: {
            fileName,
            location: file,
        },
    };
    sessionRecords.unshift(record);
    try {
        yield saveSessionRecords(sessionRecords);
        yield put({ type: successAction(action.type), payload: fromJS(record) });
    } catch (e) {
        yield put(showMessage(l('Local storage is full. Please sync the records.')));
    }
}

function* handleDeleteRecord(action) {
    const sessionRecords = (yield readSessionRecords()).filter(item => item.id !== action.id);
    try {
        yield saveSessionRecords(sessionRecords);
        yield put({ ...action, type: successAction(action.type) });
    } catch (e) {
        yield put(showMessage(l('Local storage is full. Please sync the records.')));
    }
}

function* handleUpdateLocalRecord(action) {
    const record = action.payload.toJS();

    let sessionRecords = yield readSessionRecords();
    const isExist = sessionRecords.some(item => item.id === record.id);
    sessionRecords = isExist
        ? sessionRecords.map(item => (
            item.id === record.id ? ({ ...record }) : item
        )) : (
            [record, ...sessionRecords]
        );

    try {
        yield saveSessionRecords(sessionRecords);
        yield put({ ...action, type: successAction(action.type) });
    } catch (e) {
        yield put(showMessage(l('Local storage is full. Please sync the records.')));
    }
}

function* handleSyncRecord(action) {
    const { id } = action;
    const sessionRecords = (yield readSessionRecords()).filter(item => item.id !== id);
    try {
        yield saveSessionRecords(sessionRecords);
    } catch (e) {
        yield put(showMessage(l('Local storage is full. Please sync the records.')));
    }
}

function* handleSyncAll(action) {
    yield put({ type: startAction(action.type) });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < action.payload.length; i++) {
        const record = action.payload[i];
        const syncFunc = typeof record.id === 'string' ? updateRecord : createRecord;
        yield put(syncFunc(record));
        yield take([
            successAction(CREATE_RECORD),
            successAction(UPDATE_RECORD),
            failAction(CREATE_RECORD),
            failAction(UPDATE_RECORD),
        ]);
    }

    yield put({ type: successAction(action.type) });
}

function* handleDeletePatient(action) {
    const sessionRecords = yield readSessionRecords();
    const records = sessionRecords.filter(item => (
        !item.patient || item.patient.id !== action.patientId
    ));
    yield saveSessionRecords(records);
}

export function* watchAddRecord() {
    yield takeLatest(CREATE_LOCAL_RECORD, handleAddRecord);
}

export function* watchSyncRecord() {
    yield takeLatest([successAction(CREATE_RECORD), successAction(UPDATE_RECORD)], handleSyncRecord);
}

export function* watchUpdateLocalRecord() {
    yield takeLatest(UPDATE_LOCAL_RECORD, handleUpdateLocalRecord);
}

export function* watchDeleteRecord() {
    yield takeLatest([DELETE_LOCAL_RECORD, successAction(DELETE_RECORD)], handleDeleteRecord);
}

export function* watchSyncAll() {
    yield takeLatest(SYNC_ALL, handleSyncAll);
}

export function* watchDeletePatient() {
    yield takeLatest(successAction(DELETE_PATIENT), handleDeletePatient);
}
