import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead, TableRow, TableCell, TableBody, Container,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';

import FilterSearchInput from 'inputs/FilterSearchInput';
import { Form } from 'react-final-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import InfiniteScroll from 'react-infinite-scroller';

import Device from 'components/devices/Device';
import EmptyData from 'components/EmptyData';

const styles = () => ({
    devicesBox: {
        padding: '0 250px 40px 250px',
    },
    tableContainer: {
        overflow: 'initial',
    },
    tableHead: {
        borderBottom: '1px solid #DDDDDD',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        border: '1px solid #DDDDDD',
        borderRadius: 6,
        boxShadow: 'none',
    },
    input: {
        backgroundColor: 'white',
        flex: 1,
    },
    searchInput: {
        justifyContent: 'center',
        margin: '50px auto',
        width: '50%',
    },
    iconButton: {
        padding: 10,
    },
    deviceTable: {
        width: '100%',
        tableLayout: 'fixed',
        borderSpacing: 0,
        height: 'auto',
    },
    tableHeadDevices: {
        paddingLeft: 30,
    },
});

const Devices = props => (
    <React.Fragment>
        <Box display="flex" className={props.classes.searchInput}>
            <Paper component="form" className={props.classes.root}>
                <FilterSearchInput
                    clearable
                    fieldName="name"
                    adornment={<SearchIcon className={props.classes.searchIcon} />}
                    className={props.classes.input}
                    placeholder={l('Search device by name')}
                    inputPadding={props.classes.inputPadding}
                />
            </Paper>
        </Box>
        <Container className={props.classes.devicesBox}>
            <TableContainer className={props.classes.tableContainer} component={Paper}>
                <InfiniteScroll hasMore={props.hasNext} loadMore={props.loadMore}>
                    <Table stickyHeader className={props.classes.deviceTable}>
                        <TableHead className={props.classes.tableHead}>
                            <TableRow>
                                <TableCell className={props.classes.tableHeadDevices}>
                                    {l('Devices')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!props.devices || props.devices.isEmpty()
                                ? (
                                    <TableRow className={props.classes.emptyRow}>
                                        <TableCell>
                                            <EmptyData marginDevice text={l('There are no devices yet')} />
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                            {props.devices && props.devices.map(item => (
                                <Form
                                    key={item.get('id')}
                                    initialValues={item.toJS()}
                                    component={Device}
                                    onSubmit={props.onDeviceSubmit}
                                    onClickDelete={props.onClickDelete}
                                    promptLocation={props.promptLocation}
                                    showPromptPopup={props.showPromptPopup}
                                    onClickStay={props.onClickStay}
                                    onClickLeave={props.onClickLeave}
                                    onChangePristineForm={props.onChangePristineForm}
                                    isOpenPromptConfirmPopup={props.isOpenPromptConfirmPopup}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            </TableContainer>
        </Container>
    </React.Fragment>
);
Devices.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    hasNext: PropTypes.bool,
    devices: ImmutablePropTypes.list,
    promptLocation: ImmutablePropTypes.map,
    isOpenPromptConfirmPopup: PropTypes.bool.isRequired,
    onClickStay: PropTypes.func.isRequired,
    onClickLeave: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    onDeviceSubmit: PropTypes.func.isRequired,
    onChangePristineForm: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(Devices);
