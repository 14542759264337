import { denormalize } from 'normalizr';
import { createSelector, defaultMemoize } from 'reselect';

import schemas from 'data/schemas';

import { getEntities } from 'selectors/entities';

export const getRecordIds = state => state.getIn(['records', 'ids']);

export const getLocalRecords = state => state.getIn(['records', 'local']);

export const getIsSubmitting = state => state.getIn(['records', 'submitting']);

export const getSyncingByRecord = state => id => state.getIn(['records', 'syncing', id]);

export const getSyncingGlobal = state => state.getIn(['records', 'syncingGlobal']);

export const getPage = state => state.getIn(['records', 'params', 'page']);

export const getTotalPages = state => state.getIn(['records', 'totalPages']);

export const hasNext = createSelector(
    getPage,
    getTotalPages,
    (page, totalPages) => !!(totalPages && totalPages >= (page + 1)),
);

export const getRecords = createSelector(
    getEntities,
    getRecordIds,
    (entities, ids) => denormalize(ids, schemas.RECORDS, entities),
);

export const getRecordById = createSelector(
    getEntities,
    getLocalRecords,
    (entities, localRecords) => defaultMemoize(id => {
        const record = localRecords.find(item => `${item.get('id')}` === `${id}`);
        if (record && !record.isEmpty()) {
            return record;
        }
        return denormalize(id, schemas.RECORD, entities);
    }),
);
