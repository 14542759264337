import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import AddRecord from 'components/records/AddRecord';

import { addRecord } from 'actions/records';

import { HEART } from 'data/organs';
import { Clear, S1_S2 } from 'data/resultArea';

const mapDispatchToProps = { addRecord };

const AddRecordContainer = props => {
    const handleClickSave = useCallback(values => (
        props.addRecord({
            ...values,
            isConfirmed: false,
            recognitionResult: values.organ === HEART ? S1_S2 : Clear,
        })
    ), [props.addRecord]);

    return (
        <Form
            component={AddRecord}
            onSubmit={handleClickSave}
            onClickCancel={props.onClickCancel}
        />
    );
};

AddRecordContainer.propTypes = {
    addRecord: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(memo(AddRecordContainer));
