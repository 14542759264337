import { fork, put, select, takeLatest, race, take } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';

import { RESET_PROMPT_POPUP, showPromptPopup } from 'actions/prompt';
import { failAction, successAction, UNAUTHORIZED } from 'actions/actionTypes';
import { BEGIN_LOGOUT, LOGIN, logout, LOGOUT, REFRESH_TOKEN, refreshToken } from 'actions/session';
import { RESET_PASSWORD, SEND_EMAIL_RESET_PASSWORD } from 'actions/resetPassword';
import { clearConfirmData, CONFIRM_EMAIL, setConfirmData, SIGN_UP, UPDATE_PROFILE } from 'actions/users';

import { getLocation } from 'selectors/router';
import { getAppToken } from 'selectors/session';
import { getFullName } from 'selectors/welcome';
import { getFormPristine } from 'selectors/finalForms';

import {
    watchAddRecord,
    watchDeleteRecord,
    watchSyncAll,
    watchSyncRecord,
    watchDeletePatient,
    watchUpdateLocalRecord,
} from 'sagas/records';
import { watchDeletePatient as watchDeletePatientForLocalRecords } from 'sagas/patients';
import { watchErrors, watchSuccess } from 'sagas/messages';
import { watchFinishedRequest, watchSendRequest } from 'sagas/spinner';

import { clearSession, extractSession, saveSession } from 'utils/session';

import { CONFIRM, WELCOME } from 'data/routes';
import { USER_INACTIVE, USER_IS_NOT_CONFIRMED } from 'data/errors';
import { STAY } from 'data/prompt';

const MESSAGE_WHEN_REDIRECT_TO_CONFIRMATION = [USER_IS_NOT_CONFIRMED, USER_INACTIVE];

function* saveSessionToken() {
    const token = yield select(getAppToken);
    saveSession(token);
}

function* redirectToLogin() {
    yield put(push(LOGIN));
}

function* redirectToConfirmIfNeed(action) {
    const { reason, message } = action.response.data;
    if (MESSAGE_WHEN_REDIRECT_TO_CONFIRMATION.includes(message)
        || MESSAGE_WHEN_REDIRECT_TO_CONFIRMATION.includes(reason)
        || action.type === successAction(SIGN_UP)) {
        const { email } = action;
        yield put(setConfirmData({ email }));
        yield put(push(CONFIRM));
    }
}

function* redirectAfterConfirmation() {
    yield put(clearConfirmData());
    const fullName = yield select(getFullName);
    yield put(push(fullName ? WELCOME : LOGIN));
}

function* refreshTokenAndResendApi(action) {
    const token = JSON.parse(extractSession());
    yield put(refreshToken(token.refreshToken));
    const { success } = yield race({
        success: take(successAction(REFRESH_TOKEN)),
        fail: take(failAction(REFRESH_TOKEN)),
    });
    if (success) {
        yield put({ ...action.payload });
    }
}

function* checkFormAndLogout() {
    const isPristineAnyForm = yield select(getFormPristine);
    if (!isPristineAnyForm) {
        const location = yield select(getLocation);
        yield put(showPromptPopup(location));
        const { payload: event } = yield take(RESET_PROMPT_POPUP);
        if (event === STAY) {
            return;
        }
    }
    yield put(logout());
}

function* watchLogin() {
    yield takeLatest([successAction(LOGIN)], saveSessionToken);
}
function* watchFailLogin() {
    yield takeLatest([
        failAction(LOGIN),
        successAction(SIGN_UP),
        successAction(SEND_EMAIL_RESET_PASSWORD),
    ], redirectToConfirmIfNeed);
}

function* watchConfirmEmail() {
    yield takeLatest([successAction(CONFIRM_EMAIL)], redirectAfterConfirmation);
}

function* watchUnauthorized() {
    yield takeLatest([UNAUTHORIZED], refreshTokenAndResendApi);
}

function* watchResetPassword() {
    yield takeLatest([successAction(RESET_PASSWORD)], redirectToLogin);
}

function* watchRefreshTokenSuccess() {
    yield takeLatest([successAction(REFRESH_TOKEN), successAction(UPDATE_PROFILE)], saveSessionToken);
}

function* watchLogout() {
    yield takeLatest(LOGOUT, clearSession);
}

function* watchBeginLogout() {
    yield takeLatest(BEGIN_LOGOUT, checkFormAndLogout);
}

export default function* root() {
    yield fork(watchLogin);
    yield fork(watchFailLogin);
    yield fork(watchConfirmEmail);
    yield fork(watchResetPassword);
    yield fork(watchUnauthorized);
    yield fork(watchRefreshTokenSuccess);
    yield fork(watchErrors);
    yield fork(watchSuccess);
    yield fork(watchLogout);
    yield fork(watchBeginLogout);
    yield fork(watchSendRequest);
    yield fork(watchFinishedRequest);
    yield fork(watchAddRecord);
    yield fork(watchDeleteRecord);
    yield fork(watchUpdateLocalRecord);
    yield fork(watchSyncRecord);
    yield fork(watchSyncAll);
    yield fork(watchDeletePatient);
    yield fork(watchDeletePatientForLocalRecords);
}
