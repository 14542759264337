import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import InfiniteScroll from 'react-infinite-scroller';

import {
    Box,
    Paper,
    Table,
    Button,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Container,
    Typography,
    TableContainer,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import EmptyData from 'components/EmptyData';
import PatientRow from 'components/patients/PatientRow';

import FilterSearchInput from 'inputs/FilterSearchInput';

const styles = () => ({
    patientsBox: {
        padding: '0 150px',
    },
    divider: {
        width: 'calc(100% + 48px)',
        margin: '0px 0 0 -24px',
    },
    logoWrapper: {
        display: 'flex',
        marginLeft: '5%',
        marginRight: 16,
        padding: '5px 0',
    },
    importButton: {
        fontWeight: 400,
        padding: '6px 28px',
    },
    addPatientButton: {
        padding: '7px 50px',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '30%',
        border: '1px solid #DDDDDD',
        borderRadius: 6,
        boxShadow: 'none',
    },
    input: {
        backgroundColor: 'white',
        flex: 1,
        margin: 0,
    },
    searchIcon: {
        padding: 0,
        bottom: '-3px',
    },
    patientsFirstHeader: {
        width: '39%',
        marginLeft: 21,
    },
    addPatientLink: {
        textDecoration: 'none',
    },
    inputPadding: {
        padding: '6px 5px 7px',
    },
    patientSpinnerBox: {
        width: '4%',
        margin: '20px auto',
    },
    patientTable: {
        width: '100%',
        tableLayout: 'fixed',
        borderSpacing: 0,
        height: 'auto',
    },
    emptyRow: {
        height: '60vh',
    },
    tableContainer: {
        overflow: 'auto',
        maxHeight: '65vh',
    },
});

const Patients = props => (
    <Container className={props.classes.patientsBox}>
        <Box my={4} textAlign="center">
            <Typography variant="h2" color="secondary">
                {l('Patients')}
            </Typography>
        </Box>
        <Box display="flex" mb={4}>
            <Paper component="form" className={props.classes.root}>
                <FilterSearchInput
                    clearable
                    fieldName="q"
                    adornment={<SearchIcon className={props.classes.searchIcon} />}
                    className={props.classes.input}
                    placeholder={l('Search patient by name')}
                    inputPadding={props.classes.inputPadding}
                />
            </Paper>
            <Box ml="auto" onClick={props.onClickAddPatient}>
                <Button
                    color="primary"
                    variant="contained"
                    className={props.classes.addPatientButton}
                    size="large"
                >
                    {l('+Add patient')}
                </Button>
            </Box>
        </Box>
        <TableContainer className={props.classes.tableContainer} component={Paper}>
            <InfiniteScroll
                useWindow={false}
                threshold={15}
                hasMore={props.hasNext}
                loadMore={props.onReadNext}
            >
                <Table stickyHeader className={props.classes.patientTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {l('Full name')}
                            </TableCell>
                            <TableCell>
                                {l('Date of birth')}
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            {props.withAssign && <TableCell />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !props.patients || props.patients.isEmpty() ? (
                                <TableRow className={props.classes.emptyRow}>
                                    <TableCell colSpan={props.withAssign ? 5 : 4}>
                                        <EmptyData marginOff text={l('There are no patients yet')} />
                                    </TableCell>
                                </TableRow>
                            ) : props.patients.map(item => (
                                <PatientRow
                                    key={item.get('id')}
                                    patient={item}
                                    userId={props.userId}
                                    withAssign={props.withAssign}
                                    onClickAssign={props.onClickAssign}
                                    onClickProfile={props.onClickProfile}
                                    onClickHistory={props.onClickHistory}
                                />
                            ))
                        }
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </TableContainer>
    </Container>
);

Patients.propTypes = {
    hasNext: PropTypes.bool,
    withAssign: PropTypes.bool,
    userId: PropTypes.string,
    patients: ImmutablePropTypes.list,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onReadNext: PropTypes.func.isRequired,
    onClickAssign: PropTypes.func.isRequired,
    onClickProfile: PropTypes.func.isRequired,
    onClickHistory: PropTypes.func.isRequired,
    onClickAddPatient: PropTypes.func.isRequired,
};

export default withStyles(styles)(Patients);
