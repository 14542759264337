import { List } from 'immutable';

import { dataURItoBlob } from 'utils/converters';

export const DATE = 'MM-DD-YYYY';
export const DATE_WITH_TIME = 'MM-DD-YYYY HH:mm';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const trimValue = value => (value && typeof value === 'string' ? value.trim() : value);

export const getFormattedFloat = (value, fixed = 2) => value && parseFloat(value.toFixed(fixed));

export const formatFileSize = value => value && (
    value >= 1024
        ? `${getFormattedFloat(value / 1024)} MB`
        : `${getFormattedFloat(value)} kB`
);

export const formattingRecord = record => {
    if (typeof record.get('id') === 'string') {
        return ({
            id: record.get('id'),
            patient: record.get('patient') && record.get('patient').toJS(),
            organ: record.get('organ'),
            notes: record.get('notes').toJS(),
            auscultationArea: record.get('auscultationArea'),
            recognitionResult: record.get('recognitionResult'),
            assessmentFinding: record.get('assessmentFinding'),
        });
    }
    return ({
        id: record.get('id'),
        patientId: record.getIn(['patient', 'id']),
        organ: record.get('organ'),
        auscultationArea: record.get('auscultationArea'),
        recognitionResult: record.get('recognitionResult'),
        notes: record.get('notes', List()).map(item => item.get('comment')).toJS(),
        assessmentFinding: record.get('assessmentFinding'),
        file: dataURItoBlob(record.getIn(['file', 'location'])),
        createdAt: record.get('createdAt'),
    });
};

export const parseDateFiled = date => (date ? date.format(SERVER_DATE_FORMAT) : null);
