import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Field } from 'react-final-form';

import { Box, Button, Container, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import { composeValidators, email, length, numbers, phone, required } from 'validators/validationInputFields';

import TextField from 'inputs/forms/TextField';
import DatePickerField from 'inputs/forms/DatePickerField';
import SelectField from 'inputs/forms/SelectField';

import PromptConfirmationPopup from 'components/popups/PromptConfirmationDialog';

import { getSexOptions } from 'data/sex';

import { parseDateFiled } from 'utils/formatterUtils';

const MIN_MAX_LENGTH_1_50 = length(1, 50);
const MIN_MAX_LENGTH_2_50 = length(2, 50);

const styles = () => ({
    addPatientsBox: {
        marginTop: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        boxShadow: '0 2px 15px 0 #E6EAED',
        height: '100%',
        padding: '40px 24px',
    },
    container: {
        padding: '0 150px 40px 150px',
        marginTop: 40,
    },
    addPatientFormLink: {
        textDecoration: 'none',
    },
    patientFormBox: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
    },
    patientFormChild: {
        width: '50%',
        height: '60px',
    },
    patientFormChildFull: {
        width: '100%',
        paddingRight: 89,
    },
    patientFormSelect: {
        fontSize: '1em',
        position: 'relative',
        bottom: 15,
    },
    submitButton: {
        float: 'right',
        position: 'relative',
        bottom: 32,
        right: 40,
    },
    deleteButton: {
        float: 'right',
        position: 'relative',
        bottom: 32,
        right: 60,
    },
});

const Patient = props => {
    const sex = useMemo(getSexOptions, []);

    useEffect(() => () => props.onChangePristineForm({ pristine: true }), []);

    console.log(props.pristine);

    useEffect(() => {
        props.onChangePristineForm({ pristine: props.pristine });
    }, [props.pristine]);

    useEffect(() => {
        if (props.submitSucceeded) {
            props.onSuccessSubmit();
        }
    }, [props.submitSucceeded]);

    const handleLeave = useCallback(location => {
        if (!props.pristine && !props.submitSucceeded) {
            props.showPromptPopup(location);
            return false;
        }
        return true;
    }, [props.pristine, props.submitSucceeded]);

    return (
        <Fragment>
            <PromptConfirmationPopup
                text={l('Are you sure you want to leave the screen without saving?')}
                location={props.promptLocation}
                isOpenPopup={props.isOpenPromptConfirmPopup}
                onClickOk={props.onClickLeave}
                onClickCancel={props.onClickStay}
                onLeave={handleLeave}
            />
            <Container className={props.classes.container}>
                <Box className={props.classes.addPatientFormLink} onClick={props.onClickPatientLink}>
                    <IconButton type="submit" className={props.classes.iconButton} aria-label="search">
                        <ArrowBackIosIcon />
                    </IconButton>
                </Box>
                <Box className={props.classes.addPatientsBox}>
                    <Box textAlign="center">
                        <Typography noWrap variant="h3">
                            {props.values.id && props.isShared && l('Patient\'s info (shared)')}
                            {props.values.id && !props.isShared && l('Patient\'s info')}
                            {!props.values.id && !props.isShared && l('Add patient')}
                        </Typography>
                    </Box>
                    <form onSubmit={props.handleSubmit}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="text"
                            className={props.classes.submitButton}
                            disabled={props.isShared}
                        >
                            {l('Save')}
                        </Button>
                        {
                            props.values.id && (
                                <Button
                                    color="secondary"
                                    variant="text"
                                    className={props.classes.deleteButton}
                                    onClick={props.onClickDelete}
                                    disabled={props.isShared}
                                >
                                    {l('Delete')}
                                </Button>
                            )
                        }
                        <Container className={props.classes.patientFormBox}>
                            <Box display="inline-flex" width="100%">
                                <Box px={6} mb={2} className={props.classes.patientFormChild}>
                                    <Field
                                        fullWidth
                                        name="firstName"
                                        validate={composeValidators(required, MIN_MAX_LENGTH_2_50)}
                                        placeholder={l('First name*')}
                                        component={TextField}
                                        disabled={props.isShared}
                                    />
                                </Box>
                                <Box px={6} mb={2} className={props.classes.patientFormChild}>
                                    <Field
                                        fullWidth
                                        name="lastName"
                                        validate={composeValidators(required, MIN_MAX_LENGTH_1_50)}
                                        placeholder={l('Last name*')}
                                        component={TextField}
                                        disabled={props.isShared}
                                    />
                                </Box>
                            </Box>
                            <Box display="inline-flex" width="100%" px="48px">
                                <Field
                                    fullWidth
                                    name="email"
                                    validate={composeValidators(required, email)}
                                    placeholder={l('Email*')}
                                    component={TextField}
                                    disabled={props.isShared}
                                />
                            </Box>
                            <Box display="inline-flex" width="100%">
                                <Box
                                    px={6}
                                    mb={2}
                                    paddingTop={2}
                                    className={props.classes.patientFormChild}
                                >
                                    <Field
                                        fullWidth
                                        name="phone"
                                        validate={composeValidators(required, phone)}
                                        placeholder={l('Phone*')}
                                        component={TextField}
                                        disabled={props.isShared}
                                    />
                                </Box>
                                <Box px={6} mb={6} className={props.classes.patientFormChild}>
                                    <Field
                                        openTo="year"
                                        disableFuture
                                        placeholder={l('Birthday*')}
                                        name="dateOfBirth"
                                        parse={parseDateFiled}
                                        validate={required}
                                        component={DatePickerField}
                                        disabled={props.isShared}
                                    />
                                </Box>
                            </Box>
                            <Box display="inline-flex" width="100%">
                                <Box
                                    px={6}
                                    mb={2}
                                    className={`${props.classes.patientFormChild} ${props.classes.patientFormSelect}`}
                                >
                                    <Typography variant="body2" color="secondary" noWrap>
                                        {l('Sex*')}
                                    </Typography>
                                    <Field
                                        name="gender"
                                        values={sex}
                                        component={SelectField}
                                        placeholder={l('Sex*')}
                                        validate={required}
                                        disabled={props.isShared}
                                    />
                                </Box>
                                <Box px={6} mb={2} className={props.classes.patientFormChild}>
                                    <Field
                                        fullWidth
                                        name="weight"
                                        validate={composeValidators(required, numbers)}
                                        placeholder={l('Weight*')}
                                        component={TextField}
                                        disabled={props.isShared}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">lb</InputAdornment>,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box display="inline-flex" width="100%" px="48px">
                                <Typography noWrap variant="body1">
                                    {l('Additional info')}
                                </Typography>
                            </Box>
                            <Box display="inline-flex" width="100%">
                                <Box px={6} mb={2} className={props.classes.patientFormChild}>
                                    <Field
                                        disabled
                                        fullWidth
                                        name="country"
                                        component={TextField}
                                    />
                                </Box>
                                <Box px={6} mb={2} className={props.classes.patientFormChild}>
                                    <Field
                                        fullWidth
                                        name="address"
                                        disabled={props.isShared}
                                        placeholder={l('Address')}
                                        component={TextField}
                                    />
                                </Box>
                            </Box>
                            <Box display="inline-flex" width="100%">
                                <Box px={6} mb={2} className={props.classes.patientFormChild}>
                                    <Field
                                        fullWidth
                                        name="state"
                                        disabled={props.isShared}
                                        placeholder={l('State')}
                                        component={TextField}
                                    />
                                </Box>
                                <Box px={6} mb={2} className={props.classes.patientFormChild}>
                                    <Field
                                        fullWidth
                                        name="postCode"
                                        disabled={props.isShared}
                                        placeholder={l('Postcode')}
                                        component={TextField}
                                    />
                                </Box>
                            </Box>
                            <Box display="inline-flex" width="50%">
                                <Box
                                    px={6}
                                    mb={2}
                                    width="100%"
                                >
                                    <Field
                                        fullWidth
                                        name="city"
                                        disabled={props.isShared}
                                        placeholder={l('City')}
                                        component={TextField}
                                    />
                                </Box>
                            </Box>
                        </Container>
                    </form>
                </Box>
            </Container>
        </Fragment>
    );
};

Patient.propTypes = {
    submitSucceeded: PropTypes.bool,
    values: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    pristine: PropTypes.bool.isRequired,
    isShared: PropTypes.bool.isRequired,
    promptLocation: ImmutablePropTypes.map,
    showPromptPopup: PropTypes.func.isRequired,
    isOpenPromptConfirmPopup: PropTypes.bool.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickStay: PropTypes.func.isRequired,
    onClickLeave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onSuccessSubmit: PropTypes.func.isRequired,
    onClickPatientLink: PropTypes.func.isRequired,
    onChangePristineForm: PropTypes.func.isRequired,
};

export default withStyles(styles)(Patient);
