import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { fromJS, isImmutable, Map } from 'immutable';
import { push, goBack } from 'connected-react-router/immutable';

import {
    syncAll,
    createRecord,
    updateRecord,
    deleteRecord,
    getRecordsData,
    restoreRecords,
    deleteLocalRecord,
    updateLocalRecord,
} from 'actions/records';
import { readPatient, sharePatient } from 'actions/patients';

import EmptyData from 'components/EmptyData';
import Record from 'components/records/Record';
import Records from 'components/records/Records';
import ConfirmationDialog from 'components/popups/ConfirmationDialog';
import RecordsFiltersPopup from 'components/records/RecordsFiltersPopup';

import AddRecordContainer from 'containers/records/AddRecordContainer';
import SharePatientContainer from 'containers/patients/SharePatientContainer';

import {
    getPage,
    hasNext,
    getRecords,
    getIsSubmitting,
    getLocalRecords,
    getSyncingGlobal,
    getSyncingByRecord,
} from 'selectors/records';
import { getUserId } from 'selectors/session';
import { getPatientById } from 'selectors/patients';

import filterToQuery from 'hoc/filterToQuery';

import { formattingRecord } from 'utils/formatterUtils';

import { SUCCESS, SYNCING } from 'data/syncStatuses';

const mapStateToProps = (state, ownProps) => ({
    hasNext: hasNext(state),
    page: getPage(state),
    records: getRecords(state),
    userId: getUserId(state),
    isSubmitting: getIsSubmitting(state),
    localRecords: getLocalRecords(state),
    getSyncingByRecord: getSyncingByRecord(state),
    syncGlobalRecordStatus: getSyncingGlobal(state),
    patientId: ownProps.match && ownProps.match.params.patientId,
    patient: getPatientById(state)(ownProps.match && ownProps.match.params.patientId),
});

const mapDispatchToProps = {
    push,
    goBack,
    syncAll,
    readPatient,
    sharePatient,
    deleteRecord,
    createRecord,
    updateRecord,
    getRecordsData,
    restoreRecords,
    deleteLocalRecord,
    updateLocalRecord,
};

const RecordsContainer = props => {
    const [deletingData, setDeletingData] = useState(null);
    const [isAllConfirmed, setIsAllConfirmed] = useState(Map());
    const [isOpenAddPopup, setIsOpenAddPopup] = useState(null);
    const [isOpenFilters, setIsOpenFilters] = useState(null);
    const [isOpenSharePopup, setIsOpenSharePopup] = useState(false);

    useEffect(() => {
        if (props.patientId) {
            props.readPatient(props.patientId);
        }
    }, [props.patientId]);

    useEffect(() => {
        const params = { ...props.query.toJS(), patient: true, file: true, patientId: props.patientId };
        props.getRecordsData({ params });
    }, [props.patientId, props.query]);

    useEffect(props.restoreRecords, []);

    useEffect(() => {
        setIsAllConfirmed(prevState => (
            props.records.reduce((result, item) => (
                result.has(item.get('id')) ? result.remove(item.get('id')) : result
            ), prevState)
        ));
    }, [props.localRecords, props.records]);

    const handleClickCancel = useCallback(() => {
        setDeletingData(null);
    }, []);

    const handleClickConfirm = useCallback(() => {
        const deleteFunc = typeof deletingData.get('id') === 'string'
            ? props.deleteRecord
            : props.deleteLocalRecord;
        deleteFunc(deletingData);
        setIsAllConfirmed(prevState => prevState.remove(deletingData.get('id')));
        setDeletingData(null);
    }, [deletingData, props.deleteRecord, props.deleteLocalRecord]);

    const handleClickAdd = useCallback(() => {
        setIsOpenAddPopup(true);
    }, []);

    const handleClickCancelAdd = useCallback(() => {
        setIsOpenAddPopup(false);
    }, []);

    const handleClickSync = useCallback(record => {
        const syncFunc = typeof record.get('id') === 'string' ? props.updateRecord : props.createRecord;
        syncFunc(formattingRecord(record));
    }, [props.createRecord, props.updateRecord]);

    const handleClickSyncAll = useCallback(() => {
        const records = props.localRecords.filter(item => isAllConfirmed.get(item.get('id')))
            .reduce((result, item) => (
                [formattingRecord(item), ...result]
            ), []);
        props.syncAll(records);
    }, [props.localRecords, props.syncAll, isAllConfirmed]);

    const handleChangeConfirm = useCallback((id, isConfirmed) => {
        setIsAllConfirmed(prevState => prevState.set(id, isConfirmed));
    }, []);

    const handleClickNotes = useCallback(record => {
        props.push(`/records/${record.get('id')}/notes`);
    }, [props.push]);

    const handleReadNext = useCallback(() => {
        if (!props.isSubmitting) {
            props.getRecordsData({ page: props.page + 1 });
        }
    }, [props.isSubmitting, props.page, props.getRecordsData]);

    const handleClickClearFilters = useCallback(() => {
        props.push({ search: '' });
    }, [props.push]);

    const handleClickFilters = useCallback(() => {
        setIsOpenFilters(true);
    }, []);

    const handleClickApplyFilters = useCallback(values => {
        const params = values.reduce((result, value, key) => (
            [...result, { name: key, value: isImmutable(value) ? value.toJS() : value }]
        ), []);
        props.onChangeFilterWithReplace(...params);
        setIsOpenFilters(false);
    }, [props.onChangeFilterWithReplace]);

    const handleClickCloseFilters = useCallback(() => {
        setIsOpenFilters(false);
    }, []);

    const handleClickAssignPatient = useCallback(record => {
        props.push({ pathname: '/patients', state: fromJS({ record }) });
    }, [props.push]);

    const handleClickPatient = useCallback(patient => {
        props.push(`/patients/${patient.get('id')}`);
    }, [props.push]);

    const handleClickShare = useCallback(() => {
        setIsOpenSharePopup(true);
    }, []);

    const handleClickCancelShare = useCallback(() => {
        setIsOpenSharePopup(false);
    }, []);

    const localRecords = props.patientId
        ? props.localRecords.filter(item => item.getIn(['patient', 'id']) === props.patientId)
        : props.localRecords;
    const allNotConfirmed = isAllConfirmed.every(item => !item);
    return (
        <Fragment>
            {isOpenAddPopup && <AddRecordContainer onClickCancel={handleClickCancelAdd} />}
            {
                isOpenSharePopup && (
                    <SharePatientContainer patientId={props.patientId} onClickCancel={handleClickCancelShare} />
                )
            }
            {
                isOpenFilters && (
                    <RecordsFiltersPopup
                        query={props.query}
                        onClickApply={handleClickApplyFilters}
                        onClickClose={handleClickCloseFilters}
                    />
                )
            }
            {
                deletingData && (
                    <ConfirmationDialog
                        isOpened
                        onClickOk={handleClickConfirm}
                        onClickCancel={handleClickCancel}
                    >
                        {l('Are you sure you want to delete the record? All associated information will be deleted.')}
                    </ConfirmationDialog>
                )
            }
            <Records
                patient={props.patient}
                isAllNotConfirmed={isAllConfirmed.isEmpty() ? false : allNotConfirmed}
                syncStatus={props.syncGlobalRecordStatus}
                hasNotSynced={props.localRecords && !props.localRecords.isEmpty()}
                filtersCount={props.query.size}
                goBack={props.goBack}
                onClickImport={handleClickAdd}
                onClickShare={handleClickShare}
                onClickFilters={handleClickFilters}
                onClickSyncAll={handleClickSyncAll}
                onClickClearFilters={handleClickClearFilters}
            >
                {
                    (!props.records || props.records.isEmpty()) && (!props.localRecords || props.localRecords.isEmpty())
                        ? (
                            <EmptyData text={l('There are no records yet')} />
                        ) : (
                            <InfiniteScroll
                                useWindow={false}
                                threshold={15}
                                hasMore={props.hasNext}
                                loadMore={handleReadNext}
                            >
                                {
                                    localRecords && localRecords.map(item => (
                                        <Record
                                            key={item.get('id')}
                                            isLocal
                                            record={item}
                                            syncStatus={props.getSyncingByRecord(item.get('id'))}
                                            onClickDelete={setDeletingData}
                                            onClickSync={handleClickSync}
                                            onClickNotes={handleClickNotes}
                                            onChangeConfirm={handleChangeConfirm}
                                            onClickPatient={handleClickPatient}
                                            onClickConfirm={props.updateLocalRecord}
                                            onClickAssignPatient={
                                                !props.patientId ? handleClickAssignPatient : null
                                            }
                                        />
                                    ))
                                }
                                {
                                    props.records && props.records.map(item => (
                                        <Record
                                            key={item.get('id')}
                                            isShared={props.userId !== item.get('createdBy')}
                                            record={item}
                                            syncStatus={props.getSyncingByRecord(item.get('id'))}
                                            onClickDelete={setDeletingData}
                                            onClickNotes={handleClickNotes}
                                            onChangeConfirm={handleChangeConfirm}
                                            onClickPatient={handleClickPatient}
                                            onClickConfirm={props.updateLocalRecord}
                                            onClickAssignPatient={!props.patientId ? handleClickAssignPatient : null}
                                        />
                                    ))
                                }
                            </InfiniteScroll>
                        )
                }
            </Records>
        </Fragment>
    );
};

RecordsContainer.propTypes = {
    hasNext: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    userId: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    query: ImmutablePropTypes.map,
    patient: ImmutablePropTypes.map,
    patientId: PropTypes.string,
    records: ImmutablePropTypes.list,
    localRecords: ImmutablePropTypes.list,
    syncGlobalRecordStatus: PropTypes.oneOf([SUCCESS, SYNCING]),
    syncAll: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    readPatient: PropTypes.func.isRequired,
    createRecord: PropTypes.func.isRequired,
    updateRecord: PropTypes.func.isRequired,
    deleteRecord: PropTypes.func.isRequired,
    restoreRecords: PropTypes.func.isRequired,
    getRecordsData: PropTypes.func.isRequired,
    deleteLocalRecord: PropTypes.func.isRequired,
    updateLocalRecord: PropTypes.func.isRequired,
    getSyncingByRecord: PropTypes.func.isRequired,
    onChangeFilterWithReplace: PropTypes.func.isRequired,
};

export default filterToQuery(connect(mapStateToProps, mapDispatchToProps)(memo(RecordsContainer)));
